import React, { useState } from 'react';
import Button from '../Button/Button';
import css from './SearchFilter.module.css'
import FieldSelect from '../FieldSelect/FieldSelect';
import IconCollection from '../IconCollection/IconCollection';
import { createResourceLocatorString } from '../../util/routes';
import { useHistory } from 'react-router-dom';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { USER_TYPE_DOCTOR } from '../../util/types';

const SearchFilter = ({ currentUser }) => {
  const routes = useRouteConfiguration();
  const history = useHistory();

  const [search, setSearch] = useState("");
  const userRole = currentUser?.attributes?.profile?.publicData?.userRole || '';
  const exploreLinkParams = userRole === USER_TYPE_DOCTOR ?  'jobs'  :  'doctors';

  const handleSubmit = () => {
    if (!currentUser) {
      return history.push(createResourceLocatorString('SignupPage', routes, {}, {}));
    } else {
      const searchParams = search && search.trim() ? { keywords: search.trim() } : {}
      return history.push(createResourceLocatorString('SearchPage', routes, { role:exploreLinkParams }, { ...searchParams }));
    }
  }

  return (
    <div className={css.searchContents}>
      <div className={css.searchInput}>
        <input
          placeholder='Search...'
          value={search}
          onBlur={() => handleSubmit()}
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              handleSubmit()
            }
          }}
          onChange={(e) => {
            setSearch(e.currentTarget.value);
          }}
        />
      </div>
      <div className={css.selectButton}>
        <Button className={css.searchButton} type="submit"><IconCollection name="filter_icon" /></Button>
      </div>
    </div>
  );
};

export default SearchFilter;