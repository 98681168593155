import React from 'react';


const IconCollection = ({ name }) => {
    switch (name) {
        case 'infoIcon':
            return (
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="none" stroke="#3D70A1" stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11v5m0 5a9 9 0 1 1 0-18a9 9 0 0 1 0 18m.05-13v.1h-.1V8z"/></svg>
            );
        case 'listingIcon':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 48 48"><path fill="none" stroke="#3D70A1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="m5 10l3 3l6-6M5 24l3 3l6-6M5 38l3 3l6-6m7-11h22M21 38h22M21 10h22"/></svg>
            );
        case 'inboxIcon':
            return (
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.16634 3.16699H15.833C16.7038 3.16699 17.4163 3.87949 17.4163 4.75033V14.2503C17.4163 15.1212 16.7038 15.8337 15.833 15.8337H3.16634C2.29551 15.8337 1.58301 15.1212 1.58301 14.2503V4.75033C1.58301 3.87949 2.29551 3.16699 3.16634 3.16699Z" stroke="#3D70A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.4163 4.75L9.49967 10.2917L1.58301 4.75" stroke="#3D70A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            );
        case 'logoutIcon':
            return (
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.125 16.625H3.95833C3.53841 16.625 3.13568 16.4582 2.83875 16.1613C2.54181 15.8643 2.375 15.4616 2.375 15.0417V3.95833C2.375 3.53841 2.54181 3.13568 2.83875 2.83875C3.13568 2.54181 3.53841 2.375 3.95833 2.375H7.125" stroke="#3D70A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.667 13.4587L16.6253 9.50033L12.667 5.54199" stroke="#3D70A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.625 9.5H7.125" stroke="#3D70A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            );
        case 'accountSettingIcon':
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6663 17.5V15.8333C16.6663 14.9493 16.3152 14.1014 15.69 13.4763C15.0649 12.8512 14.2171 12.5 13.333 12.5H6.66634C5.78229 12.5 4.93444 12.8512 4.30932 13.4763C3.6842 14.1014 3.33301 14.9493 3.33301 15.8333V17.5" stroke="#3D70A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.0003 9.16667C11.8413 9.16667 13.3337 7.67428 13.3337 5.83333C13.3337 3.99238 11.8413 2.5 10.0003 2.5C8.15938 2.5 6.66699 3.99238 6.66699 5.83333C6.66699 7.67428 8.15938 9.16667 10.0003 9.16667Z" stroke="#3D70A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            );
        case 'UPLOAD_ICON':
            return (
                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M50.75 36.25V45.9167C50.75 47.1985 50.2408 48.4279 49.3343 49.3343C48.4279 50.2408 47.1985 50.75 45.9167 50.75H12.0833C10.8015 50.75 9.57208 50.2408 8.66565 49.3343C7.75922 48.4279 7.25 47.1985 7.25 45.9167V36.25" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M41.0832 19.3333L28.9998 7.25L16.9165 19.3333" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M29 7.25V36.25" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
        case 'CLOCK_ICON':
            return (
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17" cy="17" r="17" fill="#2E835F" fillOpacity="0.19" />
                    <path d="M17 27C22.5228 27 27 22.5228 27 17C27 11.4772 22.5228 7 17 7C11.4772 7 7 11.4772 7 17C7 22.5228 11.4772 27 17 27Z" stroke="#2E835F" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17 11V17L21 19" stroke="#2E835F" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
        case 'uploadIcon':
            return (
                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M50.75 36.25V45.9167C50.75 47.1985 50.2408 48.4279 49.3343 49.3343C48.4279 50.2408 47.1985 50.75 45.9167 50.75H12.0833C10.8015 50.75 9.57208 50.2408 8.66565 49.3343C7.75922 48.4279 7.25 47.1985 7.25 45.9167V36.25" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M41.0834 19.3333L29 7.25L16.9167 19.3333" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M29 7.25V36.25" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
        case 'LOCATION_ICON':
            return (
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17" cy="17" r="17" fill="#FFE1BE" />
                    <path d="M24.5 15.333C24.5 21.1663 17 26.1663 17 26.1663C17 26.1663 9.5 21.1663 9.5 15.333C9.5 13.3439 10.2902 11.4362 11.6967 10.0297C13.1032 8.62318 15.0109 7.83301 17 7.83301C18.9891 7.83301 20.8968 8.62318 22.3033 10.0297C23.7098 11.4362 24.5 13.3439 24.5 15.333Z" stroke="#E28415" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17 17.833C18.3807 17.833 19.5 16.7137 19.5 15.333C19.5 13.9523 18.3807 12.833 17 12.833C15.6193 12.833 14.5 13.9523 14.5 15.333C14.5 16.7137 15.6193 17.833 17 17.833Z" stroke="#E28415" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
        case 'SHARE_ICON':
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z" stroke="#949494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z" stroke="#949494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z" stroke="#949494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.58984 13.5098L15.4198 17.4898" stroke="#949494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.4098 6.50977L8.58984 10.4898" stroke="#949494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            );
        case 'LINE_ICON':
            return (
                <svg width="89" height="1" viewBox="0 0 89 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="2.98431e-08" y1="0.5" x2="88.9835" y2="0.500011" stroke="#DBDBDB" />
                </svg>
            );
        case 'UPLOAD_PHOTO_ICON':
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 10V13C14.5 13.3978 14.342 13.7794 14.0607 14.0607C13.7794 14.342 13.3978 14.5 13 14.5H2.5C2.10218 14.5 1.72064 14.342 1.43934 14.0607C1.15804 13.7794 1 13.3978 1 13V10" stroke="#3D70A1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.5 4.75L7.75 1L4 4.75" stroke="#3D70A1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.75 1V10" stroke="#3D70A1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                
            );
        case 'UPLOAD_ICON':
            return (
                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M50.75 36.25V45.9167C50.75 47.1985 50.2408 48.4279 49.3343 49.3343C48.4279 50.2408 47.1985 50.75 45.9167 50.75H12.0833C10.8015 50.75 9.57208 50.2408 8.66565 49.3343C7.75922 48.4279 7.25 47.1985 7.25 45.9167V36.25" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M41.0832 19.3333L28.9998 7.25L16.9165 19.3333" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M29 7.25V36.25" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
        case 'CLOCK_ICON':
            return (
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17" cy="17" r="17" fill="#2E835F" fillOpacity="0.19" />
                    <path d="M17 27C22.5228 27 27 22.5228 27 17C27 11.4772 22.5228 7 17 7C11.4772 7 7 11.4772 7 17C7 22.5228 11.4772 27 17 27Z" stroke="#2E835F" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17 11V17L21 19" stroke="#2E835F" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
        case 'LOCATION_ICON':
            return (
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17" cy="17" r="17" fill="#FFE1BE" />
                    <path d="M24.5 15.333C24.5 21.1663 17 26.1663 17 26.1663C17 26.1663 9.5 21.1663 9.5 15.333C9.5 13.3439 10.2902 11.4362 11.6967 10.0297C13.1032 8.62318 15.0109 7.83301 17 7.83301C18.9891 7.83301 20.8968 8.62318 22.3033 10.0297C23.7098 11.4362 24.5 13.3439 24.5 15.333Z" stroke="#E28415" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17 17.833C18.3807 17.833 19.5 16.7137 19.5 15.333C19.5 13.9523 18.3807 12.833 17 12.833C15.6193 12.833 14.5 13.9523 14.5 15.333C14.5 16.7137 15.6193 17.833 17 17.833Z" stroke="#E28415" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
        case 'filter_icon':
            return (
                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="33.002" cy="33.002" r="33.002" fill="#3D70A1" />
                    <g clipPath="url(#clip0_3702_6086)">
                        <path d="M45.8036 44.3826L35.8467 44.3826" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M30.1561 44.3826L20.1992 44.3826" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M45.8034 33.0027L33.0017 33.0027" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M27.3113 33.0027L20.1992 33.0027" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M45.8038 21.6227L38.6918 21.6227" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M33.0009 21.6227L20.1992 21.6227" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M35.8467 48.6501L35.8467 40.1156" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M27.3118 37.2702L27.3118 28.7357" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M38.6918 25.8902L38.6918 17.3557" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_3702_6086">
                            <rect width="34.1399" height="34.1399" fill="white" transform="translate(15.9318 50.0726) rotate(-90)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        default:
            return <></>;
    }
};

export default IconCollection;